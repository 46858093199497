import { jsx as t, jsxs as C, Fragment as W } from "react/jsx-runtime";
import { AlertGroup as Zt, Alert as en, AlertVariant as De, AlertActionCloseButton as tn, Page as nn, Modal as vt, ModalVariant as rn, Button as P, TextContent as an, Text as Oe, TextVariants as ln, Spinner as bt, FormHelperText as qe, HelperText as $e, HelperTextItem as Ke, Popover as on, Icon as Fe, FormGroup as Ct, NumberInput as sn, ValidatedOptions as Q, InputGroup as be, InputGroupItem as Me, TextInput as Ce, Select as ke, MenuToggle as ce, MenuToggleStatus as Ue, SelectList as Ie, SelectOption as He, TextInputGroup as kt, TextInputGroupMain as It, ChipGroup as We, Chip as je, TextInputGroupUtilities as wt, Switch as cn, TextArea as xt, Title as dn, Card as un, CardHeader as hn, CardTitle as pn, CardBody as mn, Grid as fn, GridItem as at, PageSection as gn, JumpLinks as yn, JumpLinksItem as vn, ButtonVariant as ye, Checkbox as bn, Radio as Cn, MenuFooter as kn, Dropdown as In, DropdownList as wn, DropdownItem as lt, Masthead as xn, MastheadToggle as Tn, PageToggleButton as Sn, MastheadBrand as An, MastheadContent as En, Toolbar as ge, ToolbarContent as Pe, ToolbarItem as H, Avatar as Rn, Bullseye as Dn, EmptyState as On, EmptyStateIcon as ot, EmptyStateHeader as Fn, EmptyStateBody as Mn, EmptyStateFooter as Pn, EmptyStateActions as Ln, SearchInput as Bn, Divider as Nn, Pagination as Vn, Badge as _n } from "@patternfly/react-core";
import { createContext as Tt, useContext as St, useRef as Y, useEffect as ae, useCallback as se, useState as w, useMemo as V, forwardRef as qn, useId as At, Fragment as Et, Children as Rt, Component as $n, isValidElement as Kn } from "react";
import { useTranslation as B } from "react-i18next";
import { NetworkError as Dt } from "@keycloak/keycloak-admin-client";
import Un from "keycloak-js";
import { ExclamationCircleIcon as Hn, HelpIcon as it, EyeIcon as Wn, EyeSlashIcon as jn, TimesIcon as Ot, CubeIcon as Gn, PaypalIcon as zn, InstagramIcon as Jn, BitbucketIcon as Qn, MicrosoftIcon as Yn, TwitterIcon as Xn, StackOverflowIcon as Zn, OpenshiftIcon as er, LinkedinIcon as tr, GoogleIcon as nr, GitlabIcon as rr, FacebookSquareIcon as ar, GithubIcon as lr, MinusCircleIcon as or, PlusCircleIcon as Ft, EllipsisVIcon as ir, BarsIcon as sr, SearchIcon as cr, SyncAltIcon as dr } from "@patternfly/react-icons";
import { useFormContext as we, Controller as le, useController as Ge, FormProvider as ur, useWatch as hr } from "react-hook-form";
import { get as q, cloneDeep as pr, differenceBy as mr } from "lodash-es";
import me from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as fr } from "@patternfly/react-styles";
import { TableVariant as gr, TableText as yr } from "@patternfly/react-table";
import { Table as vr, TableHeader as br, TableBody as Cr } from "@patternfly/react-table/deprecated";
import './main.css';function ze(e, n) {
  const r = Tt(n);
  return r.displayName = e, r;
}
const kr = ["error", "errorMessage"], Re = "error_description";
function Ir(e) {
  if (typeof e == "string")
    return e;
  if (e instanceof Dt)
    return Tr(e.responseData);
  if (e instanceof Error)
    return e.message;
  throw new Error("Unable to determine error message.");
}
function wr(e) {
  if (!(e instanceof Dt))
    return;
  const n = e.responseData;
  return xr(n);
}
function xr(e) {
  if (typeof e == "object" && e !== null && Re in e && typeof e[Re] == "string")
    return e[Re];
}
function Tr(e) {
  if (!(typeof e != "object" || e === null))
    for (const n of kr) {
      const r = e[n];
      if (typeof r == "string")
        return r;
    }
}
const Sr = () => Math.floor(Math.random() * 1e3);
function Ar(e) {
  return e != null;
}
function Je(e) {
  const n = St(e);
  if (Ar(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Er() {
  const e = Y(!1), n = Y(/* @__PURE__ */ new Set());
  ae(() => (e.current = !1, () => {
    e.current = !0, r();
  }), []);
  function r() {
    n.current.forEach((a) => clearTimeout(a)), n.current.clear();
  }
  return se((a, l) => {
    if (e.current)
      throw new Error("Can't schedule a timeout on an unmounted component.");
    const o = Number(setTimeout(i, l));
    n.current.add(o);
    function i() {
      n.current.delete(o), a();
    }
    return function() {
      clearTimeout(o), n.current.delete(o);
    };
  }, []);
}
function Rr({ alerts: e, onCloseAlert: n }) {
  return /* @__PURE__ */ t(
    Zt,
    {
      "data-testid": "global-alerts",
      isToast: !0,
      style: { whiteSpace: "pre-wrap" },
      children: e.map(({ id: r, variant: a, message: l, description: o }, i) => /* @__PURE__ */ t(
        en,
        {
          "data-testid": i === 0 ? "last-alert" : void 0,
          isLiveRegion: !0,
          variant: De[a],
          component: "p",
          variantLabel: "",
          title: l,
          actionClose: /* @__PURE__ */ t(
            tn,
            {
              title: l,
              onClose: () => n(r)
            }
          ),
          children: o && /* @__PURE__ */ t("p", { children: o })
        },
        r
      ))
    }
  );
}
const Dr = 8e3, Mt = ze(
  "AlertContext",
  void 0
), Va = () => Je(Mt), Or = ({ children: e }) => {
  const { t: n } = B(), r = Er(), [a, l] = w([]), o = (c) => l((h) => h.filter((u) => u.id !== c)), i = se(
    (c, h = De.success, u) => {
      const m = {
        id: Sr(),
        message: c,
        variant: h,
        description: u
      };
      l((p) => [m, ...p]), r(() => o(m.id), Dr);
    },
    [r]
  ), s = se(
    (c, h) => {
      const u = n(c, { error: Ir(h) }), m = wr(h);
      i(u, De.danger, m);
    },
    [i, n]
  ), d = V(() => ({ addAlert: i, addError: s }), [i, s]);
  return /* @__PURE__ */ C(Mt.Provider, { value: d, children: [
    /* @__PURE__ */ t(Rr, { alerts: a, onCloseAlert: o }),
    e
  ] });
}, Fr = (e) => {
  const { t: n } = B(), r = e.error, a = Mr(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(nn, { children: /* @__PURE__ */ t(
    vt,
    {
      variant: rn.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(P, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ C(an, { children: [
        /* @__PURE__ */ t(Oe, { children: n("somethingWentWrongDescription") }),
        a && /* @__PURE__ */ t(Oe, { component: ln.small, children: a })
      ] })
    }
  ) });
};
function Mr(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function Pr(e, n, r) {
  const [a, l] = w(
    () => e.getItem(n) ?? r
  ), o = se((i) => {
    l(i), e.setItem(n, i);
  }, []);
  return ae(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(s) {
      s.storageArea === e && (s.key === null || s.key === n) && l(s.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [a, o];
}
function Pt(e, n, r) {
  const a = V(
    () => JSON.stringify(r),
    [r]
  ), [l, o] = Pr(
    e,
    n,
    a
  ), i = V(() => JSON.parse(l), [l]), s = se(
    (d) => o(JSON.stringify(d)),
    []
  );
  return [i, s];
}
const Lt = ze(
  "HelpContext",
  void 0
), Lr = () => Je(Lt), Br = ({ children: e }) => {
  const [n, r] = Pt(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(Lt.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, Nr = () => Tt(void 0);
let Le;
const _a = () => {
  const e = St(Le);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, qa = ({
  environment: e,
  children: n
}) => {
  Le = Nr();
  const r = Y(!1), [a, l] = w(!1), [o, i] = w(), s = V(() => {
    const d = new Un({
      url: e.serverBaseUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return d.onAuthLogout = () => d.login(), d;
  }, [e]);
  return ae(() => {
    if (r.current)
      return;
    s.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => l(!0)).catch((c) => i(c)), r.current = !0;
  }, [s]), o ? /* @__PURE__ */ t(Fr, { error: o }) : a ? /* @__PURE__ */ t(Le.Provider, { value: { environment: e, keycloak: s }, children: /* @__PURE__ */ t(Or, { children: /* @__PURE__ */ t(Br, { children: n }) }) }) : /* @__PURE__ */ t(bt, {});
};
function $a() {
  const n = document.getElementById("environment")?.textContent;
  if (typeof n != "string")
    throw new Error("Environment variables not found in the document.");
  try {
    return JSON.parse(n);
  } catch {
    throw new Error("Unable to parse environment variables as JSON.");
  }
}
const Ka = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: o,
  buttonTestRole: i,
  onContinue: s,
  component: d = P,
  children: c,
  ...h
}) => {
  const [u, m] = w(!1);
  return /* @__PURE__ */ C(W, { children: [
    /* @__PURE__ */ t(
      d,
      {
        variant: o,
        onClick: () => m(!0),
        isDisabled: l,
        "data-testrole": i,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      vt,
      {
        variant: "small",
        ...h,
        title: e,
        isOpen: u,
        onClose: () => m(!1),
        actions: [
          /* @__PURE__ */ t(
            P,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                m(!1), s();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            P,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => m(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: c
      }
    )
  ] });
}, Bt = ({ message: e, ...n }) => /* @__PURE__ */ t(qe, { ...n, children: /* @__PURE__ */ t($e, { children: /* @__PURE__ */ t(Ke, { icon: /* @__PURE__ */ t(Hn, {}), variant: "error", children: e }) }) }), Nt = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = Lr();
  return l ? /* @__PURE__ */ t(on, { bodyContent: e, children: /* @__PURE__ */ C(W, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (o) => o.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(Fe, { isInline: r, children: /* @__PURE__ */ t(it, {}) })
      }
    ),
    a && /* @__PURE__ */ t(Fe, { isInline: r, children: /* @__PURE__ */ t(it, {}) })
  ] }) }) : null;
};
function Qe(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const ne = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...o
}) => /* @__PURE__ */ C(
  Ct,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(Nt, { helpText: r, fieldLabelId: e }) : void 0,
    ...o,
    children: [
      l,
      a && /* @__PURE__ */ t(Bt, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), Ua = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: o,
    formState: { errors: i }
  } = we();
  return /* @__PURE__ */ t(
    ne,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        le,
        {
          ...r,
          name: e,
          control: o,
          render: ({ field: s }) => {
            const d = !!r.rules?.required, c = Qe(r.rules?.min), h = s.value ?? r.defaultValue, u = (m) => s.onChange(
              c !== void 0 ? Math.max(m, Number(c)) : m
            );
            return /* @__PURE__ */ t(
              sn,
              {
                ...l,
                id: e,
                value: h,
                validated: i[e] ? Q.error : Q.default,
                required: d,
                min: Number(c),
                max: Number(r.rules?.max),
                onPlus: () => u(h + 1),
                onMinus: () => u(h - 1),
                onChange: (m) => {
                  const p = Number(m.currentTarget.value);
                  u(isNaN(p) ? r.defaultValue : p);
                }
              }
            );
          }
        }
      )
    }
  );
}, Vr = ({
  hasReveal: e = !0,
  innerRef: n,
  isTideIdp: r = !1,
  ...a
}) => {
  const { t: l } = B(), [o, i] = w(!0);
  return /* @__PURE__ */ t(W, { children: /* @__PURE__ */ C(be, { style: { display: r ? "none" : void 0 }, children: [
    /* @__PURE__ */ t(Me, { isFill: !0, children: /* @__PURE__ */ t(
      Ce,
      {
        ...a,
        type: o ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      P,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => i(!o),
        children: o ? /* @__PURE__ */ t(Wn, {}) : /* @__PURE__ */ t(jn, {})
      }
    )
  ] }) });
}, Vt = qn(
  (e, n) => /* @__PURE__ */ t(Vr, { ...e, innerRef: n })
);
Vt.displayName = "PasswordInput";
const Ha = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: o, fieldState: i } = Ge({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ C(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          Vt,
          {
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? Q.error : Q.default,
            isDisabled: e.isDisabled,
            ...r,
            ...o
          }
        ),
        e.helperText && /* @__PURE__ */ t(qe, { children: /* @__PURE__ */ t($e, { children: /* @__PURE__ */ t(Ke, { children: e.helperText }) }) })
      ]
    }
  );
}, _r = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: o,
  ...i
}) => {
  const {
    control: s,
    formState: { errors: d }
  } = we(), [c, h] = w(!1), u = Qe(l.rules?.required) === !0;
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: u,
      error: q(d, n),
      labelIcon: o,
      children: /* @__PURE__ */ t(
        le,
        {
          ...l,
          name: n,
          control: s,
          render: ({ field: { onChange: m, value: p } }) => /* @__PURE__ */ t(
            ke,
            {
              ...i,
              onClick: () => h(!c),
              onOpenChange: () => h(!1),
              selected: ie(a) ? a.filter(
                (g) => Array.isArray(p) ? p.includes(g.key) : p === g.key
              ).map((g) => g.value) : p,
              toggle: (g) => /* @__PURE__ */ t(
                ce,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: g,
                  onClick: () => h(!c),
                  isExpanded: c,
                  isFullWidth: !0,
                  status: q(d, n) ? Ue.danger : void 0,
                  "aria-label": "toggle",
                  children: ie(a) ? a.find(
                    (T) => T.key === (Array.isArray(p) ? p[0] : p)
                  )?.value : p
                }
              ),
              onSelect: (g, T) => {
                const y = T?.toString();
                m(Array.isArray(p) ? [y] : y), h(!1);
              },
              isOpen: c,
              children: /* @__PURE__ */ t(Ie, { children: a.map((g) => /* @__PURE__ */ t(He, { value: ee(g), children: Ye(g) ? g : g.value }, ee(g))) })
            }
          )
        }
      )
    }
  );
}, fe = (e) => Ye(e) ? e : e.value, qr = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: o,
  placeholderText: i,
  onFilter: s,
  variant: d,
  ...c
}) => {
  const {
    control: h,
    formState: { errors: u }
  } = we(), [m, p] = w(!1), [g, T] = w(""), [y, v] = w(0), F = Y(), O = Qe(l.rules?.required) === !0, k = a.filter(
    (f) => fe(f).toLowerCase().startsWith(g.toLowerCase())
  ), R = V(
    () => k.map((f, I) => /* @__PURE__ */ t(
      He,
      {
        value: ee(f),
        isFocused: y === I,
        children: fe(f)
      },
      ee(f)
    )),
    [y, k]
  ), $ = (f, I) => {
    const E = k[y];
    switch (p(!0), f.key) {
      case "Enter": {
        f.preventDefault(), d !== re.typeaheadMulti ? T(fe(E)) : T(""), I.onChange(
          Array.isArray(I.value) ? [...I.value, ee(E)] : ee(E)
        ), p(!1), v(0);
        break;
      }
      case "Tab":
      case "Escape": {
        p(!1), I.onChange(void 0);
        break;
      }
      case "Backspace": {
        d === re.typeahead && I.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        f.preventDefault();
        let D = 0;
        f.key === "ArrowUp" && (y === 0 ? D = a.length - 1 : D = y - 1), f.key === "ArrowDown" && (y === a.length - 1 ? D = 0 : D = y + 1), v(D);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: O,
      error: q(u, n),
      labelIcon: o,
      children: /* @__PURE__ */ t(
        le,
        {
          ...l,
          name: n,
          control: h,
          render: ({ field: f }) => /* @__PURE__ */ t(
            ke,
            {
              ...c,
              onClick: () => p(!m),
              onOpenChange: () => p(!1),
              selected: ie(a) ? a.filter(
                (I) => Array.isArray(f.value) ? f.value.includes(I.key) : f.value === I.key
              ).map((I) => I.value) : f.value,
              toggle: (I) => /* @__PURE__ */ t(
                ce,
                {
                  ref: I,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => p(!m),
                  isExpanded: m,
                  isFullWidth: !0,
                  status: q(u, n) ? Ue.danger : void 0,
                  children: /* @__PURE__ */ C(kt, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      It,
                      {
                        placeholder: i,
                        value: d === re.typeahead && f.value ? ie(a) ? a.find(
                          (E) => E.key === (Array.isArray(f.value) ? f.value[0] : f.value)
                        )?.value : f.value : g,
                        onClick: () => p(!m),
                        onChange: (E, D) => {
                          T(D), s?.(D);
                        },
                        onKeyDown: (E) => $(E, f),
                        autoComplete: "off",
                        innerRef: F,
                        role: "combobox",
                        isExpanded: m,
                        "aria-controls": "select-typeahead-listbox",
                        children: d === re.typeaheadMulti && Array.isArray(f.value) && /* @__PURE__ */ t(We, { "aria-label": "Current selections", children: f.value.map(
                          (E, D) => /* @__PURE__ */ t(
                            je,
                            {
                              onClick: (A) => {
                                A.stopPropagation(), f.onChange(
                                  f.value.filter(
                                    (M) => M !== ee(E)
                                  )
                                );
                              },
                              children: ie(a) ? a.find((A) => E === A.key)?.value : fe(E)
                            },
                            D
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(wt, { children: (!!g || f.value) && /* @__PURE__ */ t(
                      P,
                      {
                        variant: "plain",
                        onClick: () => {
                          T(""), f.onChange(""), F?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Ot, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (I, E) => {
                I?.stopPropagation();
                const D = E?.toString();
                d === re.typeaheadMulti && Array.isArray(f.value) ? f.value.includes(D) ? f.onChange(
                  f.value.filter((A) => A !== D)
                ) : f.onChange([...f.value, D]) : (f.onChange(Array.isArray(f.value) ? [D] : D), p(!1));
              },
              isOpen: m,
              children: /* @__PURE__ */ t(Ie, { children: R })
            }
          )
        }
      )
    }
  );
};
var re = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(re || {});
const ie = (e) => typeof e[0] != "string", Ye = (e) => typeof e == "string", ee = (e) => Ye(e) ? e : e.key, $r = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(_r, { ...n }) : /* @__PURE__ */ t(qr, { ...n, variant: e }), Wa = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const i = r ?? (n ? "false" : !1), { control: s } = we();
  return /* @__PURE__ */ t(
    ne,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        le,
        {
          control: s,
          name: l.name,
          defaultValue: i,
          render: ({ field: { onChange: d, value: c } }) => /* @__PURE__ */ t(
            cn,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? c === "true" : c,
              onChange: (h, u) => {
                const m = n ? u.toString() : u;
                l.onChange?.(h, u), d(m);
              }
            }
          )
        }
      )
    }
  );
}, ja = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = Ge({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    ne,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        xt,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? Q.error : Q.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Ga = (e) => {
  const { labelIcon: n, helperText: r, ...a } = e, l = !!e.rules?.required, o = e.defaultValue ?? "", { field: i, fieldState: s } = Ge({
    ...e,
    defaultValue: o
  });
  return /* @__PURE__ */ C(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          Ce,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e["data-testid"] || e.name,
            validated: s.error ? Q.error : Q.default,
            isDisabled: e.isDisabled,
            ...a,
            ...i
          }
        ),
        r && /* @__PURE__ */ t(qe, { children: /* @__PURE__ */ t($e, { children: /* @__PURE__ */ t(Ke, { children: r }) }) })
      ]
    }
  );
}, Kr = xt, za = ({ icon: e }) => {
  const n = Ur(e);
  return /* @__PURE__ */ t(Fe, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function Ur(e) {
  switch (e) {
    case "github":
      return lr;
    case "facebook":
      return ar;
    case "gitlab":
      return rr;
    case "google":
      return nr;
    case "linkedin":
    case "linkedin-openid-connect":
      return tr;
    case "openshift-v3":
    case "openshift-v4":
      return er;
    case "stackoverflow":
      return Zn;
    case "twitter":
      return Xn;
    case "microsoft":
      return Yn;
    case "bitbucket":
      return Qn;
    case "instagram":
      return Jn;
    case "paypal":
      return zn;
    default:
      return Gn;
  }
}
const Hr = "_title_180i0_2", Wr = {
  title: Hr
}, _t = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  dn,
  {
    headingLevel: r,
    size: a,
    className: Wr.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), jr = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = At();
  return /* @__PURE__ */ C(un, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(hn, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(pn, { tabIndex: 0, children: /* @__PURE__ */ t(_t, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(mn, { className: "kc-form-panel__body", children: n })
  ] });
}, Gr = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ C(W, { children: [
    /* @__PURE__ */ t(_t, { id: a, title: n }),
    r
  ] }) });
}, zr = "_panel_1cdve_1", Jr = "_sticky_1cdve_5", st = {
  panel: zr,
  sticky: Jr
}, Qr = "kc-main-content-page-container", ct = (e) => e.replace(/\s+/g, "-"), Yr = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...a
}) => {
  const l = V(
    () => n.filter(({ isHidden: o }) => !o),
    [n]
  );
  return /* @__PURE__ */ C(fn, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ t(at, { md: 8, sm: 12, children: l.map(({ title: o, panel: i }) => {
      const s = ct(o.toLowerCase());
      return /* @__PURE__ */ t(Et, { children: r ? /* @__PURE__ */ t(
        jr,
        {
          scrollId: s,
          title: o,
          className: st.panel,
          children: i
        }
      ) : /* @__PURE__ */ t(Gr, { scrollId: s, title: o, children: i }) }, o);
    }) }),
    /* @__PURE__ */ t(at, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(gn, { className: st.sticky, children: /* @__PURE__ */ t(
      yn,
      {
        isVertical: !0,
        scrollableSelector: `#${Qr}`,
        label: e,
        offset: 100,
        children: l.map(({ title: o }) => {
          const i = ct(o.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              vn,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: o
              },
              o
            )
          );
        })
      }
    ) }) })
  ] });
}, Xr = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Ja = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...o
}) => /* @__PURE__ */ t(
  P,
  {
    variant: "primary",
    isDisabled: e && !Xr(e, a, r) || n,
    ...o,
    type: "submit",
    children: l
  }
), Zr = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, ea = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = V(
    () => r.map((o) => ({
      key: o,
      value: e(`locale_${o}`, Zr(o) ?? o)
    })).sort((o, i) => o.value.localeCompare(i.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(ur, { ...n, children: /* @__PURE__ */ t(
    $r,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, Be = (e) => e?.includes("${"), Ne = (e) => e.substring(2, e.length - 1), te = (e, n, r, a) => {
  const l = n || r, o = Be(l) ? Ne(l) : l, i = a ? `${a}.${o}` : o;
  return e(i || "");
}, Ve = (e, n) => te(e, n.displayName, n.name), ta = ["username", "firstName", "lastName", "email"], qt = (e) => e && ta.includes(e), X = (e) => `${qt(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Qa = (e) => e.replaceAll(".", "🍺"), Ya = (e) => e.replaceAll("🍺", ".");
function Xa(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((o) => Be(o.toString()) ? r(Ne(o)) : o)
    );
    n(X(a.field), {
      message: r(
        Be(a.errorMessage) ? Ne(a.errorMessage) : a.errorMessage,
        {
          ...l,
          defaultValue: a.errorMessage || a.field
        }
      ),
      type: "server"
    });
  });
}
function xe({
  required: e,
  validators: n
}) {
  return e || na(n);
}
function na(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Za(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return dt(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(dt);
}
function dt(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const de = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const o = te(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, s = a?.(r), d = q(i, X(r.name));
  return /* @__PURE__ */ C(
    Ct,
    {
      label: Ve(e, r) || "",
      fieldId: r.name,
      isRequired: xe(r),
      labelIcon: o ? /* @__PURE__ */ t(Nt, { helpText: o, fieldLabelId: r.name }) : void 0,
      children: [
        s ? /* @__PURE__ */ C(be, { children: [
          l,
          s
        ] }) : l,
        d && /* @__PURE__ */ t(
          Bt,
          {
            "data-testid": `${r.name}-helper`,
            message: d.message
          }
        )
      ]
    },
    r.name
  );
}, ra = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(de, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  aa,
  {
    t: e,
    form: n,
    "aria-label": Ve(e, r),
    name: X(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: Ve(e, r)
    }),
    ...l
  }
) }), aa = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: o = !1,
  defaultValue: i,
  id: s,
  ...d
}) => {
  const { register: c, setValue: h, control: u } = a, m = hr({
    name: n,
    control: u,
    defaultValue: i || ""
  }), p = V(() => Array.isArray(m) && m.length !== 0 ? m : i || [""], [m]), g = (O) => {
    v([...p.slice(0, O), ...p.slice(O + 1)]);
  }, T = () => {
    v([...p, ""]);
  }, y = (O, k) => {
    v([...p.slice(0, O), k, ...p.slice(O + 1)]);
  }, v = (O) => {
    const k = O.flatMap((R) => R);
    h(n, k, {
      shouldDirty: !0
    });
  }, F = r.startsWith("html") ? r.substring(6) : "text";
  return ae(() => {
    c(n);
  }, [c]), /* @__PURE__ */ t("div", { id: s, children: p.map((O, k) => /* @__PURE__ */ C(Et, { children: [
    /* @__PURE__ */ C(be, { children: [
      /* @__PURE__ */ t(Me, { isFill: !0, children: /* @__PURE__ */ t(
        Ce,
        {
          "data-testid": n + k,
          onChange: (R, $) => y(k, $),
          name: `${n}.${k}.value`,
          value: O,
          isDisabled: o,
          type: F,
          ...d
        }
      ) }),
      /* @__PURE__ */ t(Me, { children: /* @__PURE__ */ t(
        P,
        {
          "data-testid": "remove" + k,
          variant: ye.link,
          onClick: () => g(k),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: p.length === 1 || o,
          children: /* @__PURE__ */ t(or, {})
        }
      ) })
    ] }),
    k === p.length - 1 && /* @__PURE__ */ C(
      P,
      {
        variant: ye.link,
        onClick: T,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !O || o,
        children: [
          /* @__PURE__ */ t(Ft, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, k)) });
}, ut = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = xe(a), o = r.startsWith("multiselect"), i = o ? bn : Cn, s = a.validators?.options?.options || [], d = a.annotations?.inputOptionLabels || {}, c = a.annotations?.inputOptionLabelsI18nPrefix;
  return /* @__PURE__ */ t(de, { ...e, children: /* @__PURE__ */ t(
    le,
    {
      name: X(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: h }) => /* @__PURE__ */ t(W, { children: s.map((u) => /* @__PURE__ */ t(
        i,
        {
          id: u,
          "data-testid": u,
          label: te(e.t, d[u], u, c),
          value: u,
          isChecked: h.value.includes(u),
          onChange: () => {
            o ? h.value.includes(u) ? h.onChange(
              h.value.filter((m) => m !== u)
            ) : h.onChange([...h.value, u]) : h.onChange([u]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        u
      )) })
    }
  ) });
}, la = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: o,
  direction: i,
  width: s,
  maxHeight: d,
  toggleIcon: c,
  className: h,
  isDisabled: u,
  children: m,
  ...p
}) => {
  const [g, T] = w(!1), y = Y(), v = () => {
    T(!g), n(!g);
  }, F = () => o === "parent" && y.current?.parentElement || "inline", O = Rt.toArray(
    m
  );
  return /* @__PURE__ */ t(
    ke,
    {
      ref: y,
      maxMenuHeight: ve(d),
      isScrollable: !0,
      popperProps: {
        appendTo: F(),
        direction: i,
        width: ve(s)
      },
      ...p,
      onClick: v,
      onOpenChange: (k) => {
        k !== g && v();
      },
      selected: a,
      onSelect: (k, R) => {
        r?.(R || ""), v();
      },
      toggle: (k) => /* @__PURE__ */ t(
        ce,
        {
          id: e,
          ref: k,
          className: h,
          onClick: v,
          isExpanded: l,
          "aria-label": p["aria-label"],
          icon: c,
          isDisabled: u,
          isFullWidth: !0,
          children: O.find((R) => R.props.value === a)?.props.children || a || p["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(Ie, { children: m })
    }
  );
}, oa = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: o,
  placeholderText: i,
  maxHeight: s,
  width: d,
  toggleIcon: c,
  direction: h,
  selections: u,
  typeAheadAriaLabel: m,
  chipGroupComponent: p,
  chipGroupProps: g,
  footer: T,
  isDisabled: y,
  children: v,
  ...F
}) => {
  const [O, k] = w(""), [R, $] = w(0), f = Y(), I = Rt.toArray(
    v
  ), E = () => {
    r?.(!F.isOpen);
  }, D = (A) => {
    const M = I[R];
    switch (r?.(!0), A.key) {
      case "Enter": {
        A.preventDefault(), l !== J.typeaheadMulti ? k(M.props.value) : k(""), n?.(M.props.value), r?.(!1), $(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === J.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        A.preventDefault();
        let L = 0;
        A.key === "ArrowUp" && (R === 0 ? L = I.length - 1 : L = R - 1), A.key === "ArrowDown" && (R === I.length - 1 ? L = 0 : L = R + 1), $(L);
        break;
      }
    }
  };
  return /* @__PURE__ */ C(
    ke,
    {
      ...F,
      onClick: E,
      onOpenChange: (A) => r?.(A),
      onSelect: (A, M) => n?.(M || ""),
      maxMenuHeight: ve(s),
      popperProps: { direction: h, width: ve(d) },
      toggle: (A) => /* @__PURE__ */ t(
        ce,
        {
          ref: A,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: c,
          isDisabled: y,
          isExpanded: F.isOpen,
          isFullWidth: !0,
          status: o === "error" ? Ue.danger : void 0,
          children: /* @__PURE__ */ C(kt, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              It,
              {
                placeholder: i,
                value: l === J.typeahead && u ? u : O,
                onClick: E,
                onChange: (M, L) => {
                  k(L), a?.(L);
                },
                onKeyDown: (M) => D(M),
                autoComplete: "off",
                innerRef: f,
                role: "combobox",
                isExpanded: F.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": m,
                children: l === J.typeaheadMulti && Array.isArray(u) && (p || /* @__PURE__ */ t(We, { ...g, children: u.map((M, L) => /* @__PURE__ */ t(
                  je,
                  {
                    onClick: (Te) => {
                      Te.stopPropagation(), n?.(M);
                    },
                    children: M
                  },
                  L
                )) }))
              }
            ),
            /* @__PURE__ */ t(wt, { children: !!O && /* @__PURE__ */ t(
              P,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), k(""), a?.(""), f?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(Ot, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(Ie, { children: v }),
        T && /* @__PURE__ */ t(kn, { children: T })
      ]
    }
  );
};
var J = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(J || {});
const ve = (e) => typeof e == "number" ? e + "px" : e, ia = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(la, { ...n }) : /* @__PURE__ */ t(oa, { ...n, variant: e }), ht = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [o, i] = w(!1), [s, d] = w(""), c = a === "multiselect", h = (y, v) => {
    c ? v.value.includes(y) ? v.onChange(v.value.filter((F) => F !== y)) : Array.isArray(v.value) ? v.onChange([...v.value, y]) : v.onChange([y]) : v.onChange(y === v.value ? "" : y);
  }, u = l.validators?.options?.options || [], m = l.annotations?.inputOptionLabels || {}, p = l.annotations?.inputOptionLabelsI18nPrefix, g = (y) => te(e.t, m[y], y, p), T = (y) => u.filter(
    (v) => g(v).toLowerCase().includes(s.toLowerCase())
  ).map((v) => /* @__PURE__ */ t(
    He,
    {
      selected: y === v,
      value: v,
      children: g(v)
    },
    v
  ));
  return /* @__PURE__ */ t(de, { ...e, children: /* @__PURE__ */ t(
    le,
    {
      name: X(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: y }) => /* @__PURE__ */ t(
        ia,
        {
          toggleId: l.name,
          onToggle: (v) => i(v),
          onClear: () => h("", y),
          onSelect: (v) => {
            const F = v.toString();
            h(F, y), Array.isArray(y.value) || i(!1);
          },
          selections: c && Array.isArray(y.value) ? y.value : g(y.value),
          variant: c ? J.typeaheadMulti : u.length >= 10 ? J.typeahead : J.single,
          "aria-label": n("selectOne"),
          isOpen: o,
          isDisabled: l.readOnly,
          onFilter: (v) => (d(v), T(y.value)),
          children: T(y.value)
        }
      )
    }
  ) });
}, sa = (e) => {
  const { form: n, attribute: r } = e, a = xe(r);
  return /* @__PURE__ */ t(de, { ...e, children: /* @__PURE__ */ t(
    Kr,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(X(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, _ = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = xe(a), o = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(de, { ...e, children: /* @__PURE__ */ t(
    Ce,
    {
      id: a.name,
      "data-testid": a.name,
      type: o,
      placeholder: te(
        e.t,
        a.annotations?.inputTypePlaceholder,
        a.name,
        a.annotations?.inputOptionLabelsI18nPrefix
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(X(a.name))
    }
  ) });
}, _e = {
  text: _,
  textarea: sa,
  select: ht,
  "select-radiobuttons": ut,
  multiselect: ht,
  "multiselect-checkboxes": ut,
  "html5-email": _,
  "html5-tel": _,
  "html5-url": _,
  "html5-number": _,
  "html5-range": _,
  "html5-datetime-local": _,
  "html5-date": _,
  "html5-month": _,
  "html5-time": _,
  "multi-input": ra
}, el = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: o = !1,
  renderer: i
}) => {
  const s = V(() => {
    if (!r.attributes)
      return [];
    const d = o ? r.attributes.filter(({ readOnly: c }) => !c) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((c) => ({
      group: c,
      attributes: d.filter(
        (h) => h.group === c.name
      )
    }));
  }, [
    o,
    r.groups,
    r.attributes
  ]);
  return s.length === 0 ? null : /* @__PURE__ */ t(
    Yr,
    {
      label: e("jumpToSection"),
      sections: s.filter((d) => d.attributes.length > 0).map(({ group: d, attributes: c }) => ({
        title: te(e, d.displayHeader, d.name) || e("general"),
        panel: /* @__PURE__ */ C("div", { className: "pf-v5-c-form", children: [
          d.displayDescription && /* @__PURE__ */ t(Oe, { className: "pf-v5-u-pb-lg", children: te(e, d.displayDescription, "") }),
          c.map((h) => /* @__PURE__ */ t(
            ca,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: i,
              attribute: h
            },
            h.name
          ))
        ] })
      }))
    }
  );
}, ca = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: o
}) => {
  const i = n.watch(
    X(o.name)
  ), s = V(() => ua(o), [o]), d = o.multivalued || pa(i) && o.annotations?.inputType === void 0 ? _e["multi-input"] : _e[s];
  return o.name === "locale" ? /* @__PURE__ */ t(
    ea,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: o
    }
  ) : /* @__PURE__ */ t(
    d,
    {
      t: e,
      form: n,
      inputType: s,
      attribute: o,
      renderer: r
    }
  );
}, da = "text";
function ua(e) {
  if (qt(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return ha(n) ? n : da;
}
const ha = (e) => typeof e == "string" && e in _e, pa = (e) => Array.isArray(e) && e.length > 1, ma = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ C(
  "svg",
  {
    className: fr(
      me.avatar,
      me.modifiers[r],
      n === "light" && me.modifiers.light,
      n === "dark" && me.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ C("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), pt = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, o] = w(!1);
  return /* @__PURE__ */ t(
    In,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      onOpenChange: (i) => o(i),
      toggle: (i) => /* @__PURE__ */ t(
        ce,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: i,
          onClick: () => o(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(ir, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(wn, { children: r })
    }
  );
};
function fa(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const tl = ({
  keycloak: e,
  brand: { src: n, alt: r, className: a, ...l },
  avatar: o,
  features: {
    hasLogout: i = !0,
    hasManageAccount: s = !0,
    hasUsername: d = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: h = [],
  toolbarItems: u,
  ...m
}) => {
  const { t: p } = B(), g = [];
  s && g.push(
    /* @__PURE__ */ t(
      lt,
      {
        onClick: () => e.accountManagement(),
        children: p("manageAccount")
      },
      "manageAccount"
    )
  ), i && g.push(
    /* @__PURE__ */ t(lt, { onClick: () => e.logout(), children: p("signOut") }, "signOut")
  );
  const T = e.idTokenParsed?.picture;
  return /* @__PURE__ */ C(xn, { ...m, children: [
    /* @__PURE__ */ t(Tn, { children: /* @__PURE__ */ t(Sn, { variant: "plain", "aria-label": p("navigation"), children: /* @__PURE__ */ t(sr, {}) }) }),
    /* @__PURE__ */ t(An, { ...l, children: /* @__PURE__ */ t("img", { src: n, alt: r, className: a }) }),
    /* @__PURE__ */ t(En, { children: /* @__PURE__ */ t(ge, { children: /* @__PURE__ */ C(Pe, { children: [
      u?.map((y, v) => /* @__PURE__ */ t(H, { align: { default: "alignRight" }, children: y }, v)),
      /* @__PURE__ */ t(
        H,
        {
          visibility: {
            default: "hidden",
            md: "visible"
          },
          children: /* @__PURE__ */ t(
            pt,
            {
              "data-testid": "options",
              dropDownItems: [...h, g],
              title: d ? fa(e.idTokenParsed, p) : void 0
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        H,
        {
          align: { default: "alignLeft" },
          visibility: {
            md: "hidden"
          },
          children: /* @__PURE__ */ t(
            pt,
            {
              "data-testid": "options-kebab",
              isKebab: !0,
              dropDownItems: [
                ...c || h,
                g
              ]
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        H,
        {
          variant: "overflow-menu",
          align: { default: "alignRight" },
          className: "pf-v5-u-m-0-on-lg",
          children: T || o?.src ? /* @__PURE__ */ t(Rn, { src: T, alt: p("avatar"), ...o }) : /* @__PURE__ */ t(ma, { ...o })
        }
      )
    ] }) }) })
  ] });
}, $t = ze("ErrorBoundaryContext", void 0), Kt = () => Je($t);
class nl extends $n {
  state = {};
  static getDerivedStateFromError = (n) => ({ error: n });
  showBoundary = (n) => {
    this.setState({ error: n });
  };
  render() {
    return /* @__PURE__ */ t(
      $t.Provider,
      {
        value: { error: this.state.error, showBoundary: this.showBoundary },
        children: this.props.children
      }
    );
  }
}
const rl = ({ children: e, fallback: n }) => {
  const { error: r } = Kt();
  return r ? /* @__PURE__ */ t(n, { error: r }) : e;
};
function ga(e, n, r) {
  const { showBoundary: a } = Kt();
  ae(() => {
    const l = new AbortController(), { signal: o } = l;
    return e().then((i) => {
      o.aborted || n(i);
    }).catch((i) => {
      console.error(i), o.aborted || a(i);
    }), () => l.abort();
  }, r);
}
const ya = () => {
  const { t: e } = B();
  return /* @__PURE__ */ t(Dn, { children: /* @__PURE__ */ t(bt, { "aria-label": e("spinnerLoading") }) });
}, va = ({
  message: e,
  instructions: n,
  onPrimaryAction: r,
  hasIcon: a = !0,
  isSearchVariant: l,
  primaryActionText: o,
  secondaryActions: i,
  icon: s,
  isDisabled: d = !1
}) => /* @__PURE__ */ C(On, { "data-testid": "empty-state", variant: "lg", children: [
  a && l ? /* @__PURE__ */ t(ot, { icon: cr }) : a && /* @__PURE__ */ t(ot, { icon: s || Ft }),
  /* @__PURE__ */ t(Fn, { titleText: e, headingLevel: "h1" }),
  /* @__PURE__ */ t(Mn, { children: n }),
  /* @__PURE__ */ C(Pn, { children: [
    o && /* @__PURE__ */ t(
      P,
      {
        "data-testid": `${e.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: "primary",
        onClick: r,
        isDisabled: d,
        children: o
      }
    ),
    i && /* @__PURE__ */ t(Ln, { children: i.map((c) => /* @__PURE__ */ t(
      P,
      {
        "data-testid": `${c.text.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: c.type || ye.secondary,
        onClick: c.onClick,
        isDisabled: d,
        children: c.text
      },
      c.text
    )) })
  ] })
] }), ba = ({
  toolbarItem: e,
  subToolbar: n,
  toolbarItemFooter: r,
  children: a,
  searchTypeComponent: l,
  inputGroupName: o,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: s
}) => {
  const { t: d } = B(), [c, h] = w(""), u = () => {
    c !== "" ? (h(c), s?.(c)) : (h(""), s?.(""));
  }, m = (p) => {
    p.key === "Enter" && u();
  };
  return /* @__PURE__ */ C(W, { children: [
    /* @__PURE__ */ t(ge, { children: /* @__PURE__ */ C(Pe, { children: [
      o && /* @__PURE__ */ t(H, { children: /* @__PURE__ */ C(be, { "data-testid": o, children: [
        l,
        i && /* @__PURE__ */ t(
          Bn,
          {
            "data-testid": "table-search-input",
            placeholder: i,
            "aria-label": d("search"),
            value: c,
            onChange: (p, g) => {
              h(g);
            },
            onSearch: u,
            onKeyDown: m,
            onClear: () => {
              h(""), s?.("");
            }
          }
        )
      ] }) }),
      e
    ] }) }),
    n && /* @__PURE__ */ t(ge, { children: /* @__PURE__ */ t(Pe, { children: n }) }),
    /* @__PURE__ */ t(Nn, {}),
    a,
    /* @__PURE__ */ t(ge, { children: r })
  ] });
}, mt = ({
  id: e,
  variant: n = "top",
  count: r,
  first: a,
  max: l,
  onNextClick: o,
  onPreviousClick: i,
  onPerPageSelect: s
}) => {
  const { t: d } = B(), c = Math.round(a / l);
  return /* @__PURE__ */ t(
    Vn,
    {
      widgetId: e,
      titles: {
        paginationAriaLabel: `${d("pagination")} ${n} `
      },
      isCompact: !0,
      toggleTemplate: ({
        firstIndex: h,
        lastIndex: u
      }) => /* @__PURE__ */ C("b", { children: [
        h,
        " - ",
        u
      ] }),
      itemCount: r + c * l,
      page: c + 1,
      perPage: l,
      onNextClick: (h, u) => o((u - 1) * l),
      onPreviousClick: (h, u) => i((u - 1) * l),
      onPerPageSelect: (h, u, m) => s(m - 1, u),
      variant: n
    }
  );
}, Ca = ({
  count: e,
  searchTypeComponent: n,
  toolbarItem: r,
  subToolbar: a,
  children: l,
  inputGroupName: o,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: s,
  ...d
}) => /* @__PURE__ */ t(
  ba,
  {
    searchTypeComponent: n,
    toolbarItem: /* @__PURE__ */ C(W, { children: [
      r,
      /* @__PURE__ */ t(H, { variant: "pagination", children: /* @__PURE__ */ t(mt, { count: e, ...d }) })
    ] }),
    subToolbar: a,
    toolbarItemFooter: e !== 0 ? /* @__PURE__ */ t(H, { variant: "pagination", children: /* @__PURE__ */ t(mt, { count: e, variant: "bottom", ...d }) }) : null,
    inputGroupName: o,
    inputGroupPlaceholder: i,
    inputGroupOnEnter: s,
    children: l
  }
);
function ka({
  columns: e,
  rows: n,
  actions: r,
  actionResolver: a,
  ariaLabelKey: l,
  onSelect: o,
  onCollapse: i,
  canSelectAll: s,
  isNotCompact: d,
  isRadio: c,
  ...h
}) {
  const { t: u } = B();
  return /* @__PURE__ */ C(
    vr,
    {
      ...h,
      variant: d ? void 0 : gr.compact,
      onSelect: o ? (m, p, g) => o(p, g) : void 0,
      onCollapse: i ? (m, p, g) => i(g, p) : void 0,
      selectVariant: "checkbox",
      canSelectAll: s,
      cells: e.map((m) => ({ ...m, title: u(m.displayKey || m.name) })),
      rows: n,
      actions: r,
      actionResolver: a,
      "aria-label": u(l),
      children: [
        /* @__PURE__ */ t(br, {}),
        /* @__PURE__ */ t(Cr, {})
      ]
    }
  );
}
function Ia({
  ariaLabelKey: e,
  searchPlaceholderKey: n,
  isPaginated: r = !1,
  onSelect: a,
  canSelectAll: l = !1,
  isNotCompact: o,
  isRadio: i,
  detailColumns: s,
  isRowDisabled: d,
  loader: c,
  columns: h,
  actions: u,
  actionResolver: m,
  searchTypeComponent: p,
  toolbarItem: g,
  subToolbar: T,
  emptyState: y,
  icon: v,
  isSearching: F = !1,
  ...O
}) {
  const { t: k } = B(), [R, $] = w([]), [f, I] = w(), [E, D] = w(), [A, M] = w(!1), [L, Te] = Pt(
    localStorage,
    "pageSize",
    10
  ), [j, Ut] = w(L), [K, oe] = w(0), [G, Se] = w(""), Xe = Y(), [ue, Ht] = w(0), Ze = Y(), et = () => Ht(ue + 1), Wt = At(), tt = (b, x) => b.map((S) => {
    if (S.cellRenderer) {
      const U = S.cellRenderer;
      return { title: /* @__PURE__ */ t(U, { ...x }) };
    }
    return q(x, S.name);
  }), nt = (b) => {
    const x = (S) => s?.[0]?.enabled?.(S);
    return b.map((S, U) => {
      const z = d ? d(S) : !1, N = [
        {
          data: S,
          disableSelection: z,
          disableActions: z,
          selected: !!R.find((rt) => {
            const Yt = q(S, "id") ?? q(S, "draftRecordId"), Xt = q(rt, "id") ?? q(rt, "draftRecordId");
            return Yt === Xt;
          }),
          isOpen: x(S) ? !1 : void 0,
          cells: tt(h, S)
        }
      ];
      return s && N.push({
        parent: U * 2,
        cells: x(S) ? tt(s, S) : []
      }), N;
    }).flat();
  }, Ae = (b) => ["string", "number"].includes(typeof b) ? b.toString() : b instanceof Array ? b.map(Ae).join("") : typeof b == "object" ? Ae(
    Kn(b.title) ? b.title.props : Object.values(b)
  ) : "", he = V(
    () => G === "" || r ? void 0 : nt(E || []).filter(
      (b) => b.cells.some(
        (x) => x && Ae(x).toLowerCase().includes(G.toLowerCase())
      )
    ).slice(K, K + j + 1),
    [G, K, j]
  );
  ae(() => {
    if (l) {
      const b = document.getElementsByClassName("pf-v5-c-table__check").item(0);
      if (b) {
        const x = b.children.item(
          0
        );
        x.indeterminate = R.length > 0 && R.length < (he || f).length;
      }
    }
  }, [R]), ga(
    async () => {
      M(!0);
      const b = Xe.current === "" && G !== "";
      return b && oe(0), Xe.current = G, typeof c == "function" ? ue === Ze.current && E ? E : await c(b ? 0 : K, j + 1, G) : c;
    },
    (b) => {
      Ze.current = ue, r || (D(b), b.length > K ? b = b.slice(K, K + j + 1) : oe(0));
      const x = nt(b);
      I(x), M(!1);
    },
    [
      ue,
      K,
      j,
      G,
      typeof c != "function" ? c : void 0
    ]
  );
  const jt = () => u && pr(u).map((b, x) => (delete b.onRowClick, b.onClick = async (S, U) => {
    await u[x].onRowClick(
      (he || f)[U].data
    ) && (r || Se(""), et());
  }, b)), Gt = (b, x) => {
    const S = he || f;
    if (i) {
      S.forEach((z) => {
        z.selected = !1;
      }), S[x].selected = b, I([...f]);
      const U = b ? [S[x].data] : [];
      $(U), a(U);
    } else {
      x === -1 ? I(
        S.map((N) => (N.selected = b, N))
      ) : (S[x].selected = b, I([...f]));
      const z = [
        ...mr(
          R,
          S.map((N) => N.data),
          "id"
        ),
        ...S.filter((N) => N.selected).map((N) => N.data)
      ];
      $(z), a(z);
    }
  }, zt = (b, x) => {
    Z[x].isOpen = b, I([...Z]);
  }, Z = he || f, pe = !Z || Z.length === 0, Ee = G !== "" || F, Jt = s ? j * 2 : j, Qt = s ? (Z?.length || 0) / 2 : Z?.length || 0;
  return /* @__PURE__ */ C(W, { children: [
    (A || !pe || Ee) && /* @__PURE__ */ C(
      Ca,
      {
        id: Wt,
        count: Qt,
        first: K,
        max: j,
        onNextClick: oe,
        onPreviousClick: oe,
        onPerPageSelect: (b, x) => {
          oe(b), Ut(x), Te(x);
        },
        inputGroupName: n ? `${e}input` : void 0,
        inputGroupOnEnter: Se,
        inputGroupPlaceholder: k(n || ""),
        searchTypeComponent: p,
        toolbarItem: /* @__PURE__ */ C(W, { children: [
          g,
          " ",
          /* @__PURE__ */ t(H, { variant: "separator" }),
          " ",
          /* @__PURE__ */ t(H, { children: /* @__PURE__ */ C(P, { variant: "link", onClick: et, children: [
            /* @__PURE__ */ t(dr, {}),
            " ",
            k("refresh")
          ] }) })
        ] }),
        subToolbar: T,
        children: [
          !A && !pe && /* @__PURE__ */ t(
            ka,
            {
              ...O,
              canSelectAll: l,
              onSelect: a ? Gt : void 0,
              onCollapse: s ? zt : void 0,
              actions: jt(),
              actionResolver: m,
              rows: Z.slice(0, Jt),
              columns: h,
              isNotCompact: o,
              isRadio: i,
              ariaLabelKey: e
            }
          ),
          !A && pe && Ee && /* @__PURE__ */ t(
            va,
            {
              hasIcon: !0,
              icon: v,
              isSearchVariant: !0,
              message: k("noSearchResults"),
              instructions: k("noSearchResultsInstructions"),
              secondaryActions: F ? [] : [
                {
                  text: k("clearAllFilters"),
                  onClick: () => Se(""),
                  type: ye.link
                }
              ]
            }
          ),
          A && /* @__PURE__ */ t(ya, {})
        ]
      }
    ),
    !A && pe && !Ee && y
  ] });
}
const wa = ({ link: e, organization: n }) => {
  const { t: r } = B();
  return /* @__PURE__ */ t(yr, { wrapModifier: "truncate", children: /* @__PURE__ */ C(e, { organization: n, children: [
    n.name,
    !n.enabled && /* @__PURE__ */ t(
      _n,
      {
        isRead: !0,
        className: "pf-v5-u-ml-sm",
        children: r("disabled")
      },
      `${n.id}-disabled`
    )
  ] }) });
}, xa = (e) => {
  const { t: n } = B();
  return /* @__PURE__ */ t(
    We,
    {
      numChips: 2,
      expandedText: n("hide"),
      collapsedText: n("showRemaining"),
      children: e.domains?.map((r) => {
        const a = typeof r == "string" ? r : r.name;
        return /* @__PURE__ */ t(je, { isReadOnly: !0, children: a }, a);
      })
    }
  );
}, al = ({
  loader: e,
  toolbarItem: n,
  isPaginated: r = !1,
  onSelect: a,
  onDelete: l,
  deleteLabel: o = "delete",
  link: i,
  children: s
}) => {
  const { t: d } = B();
  return /* @__PURE__ */ t(
    Ia,
    {
      loader: e,
      isPaginated: r,
      ariaLabelKey: "organizationList",
      searchPlaceholderKey: "searchOrganization",
      toolbarItem: n,
      onSelect: a,
      canSelectAll: a !== void 0,
      actions: l ? [
        {
          title: d(o),
          onRowClick: l
        }
      ] : void 0,
      columns: [
        {
          name: "name",
          displayKey: "name",
          cellRenderer: (c) => /* @__PURE__ */ t(wa, { link: i, organization: c })
        },
        {
          name: "domains",
          displayKey: "domains",
          cellRenderer: xa
        },
        {
          name: "description",
          displayKey: "description"
        }
      ],
      emptyState: s
    }
  );
}, ft = "pf-v5-theme-dark", gt = window.matchMedia("(prefers-color-scheme: dark)");
function yt(e) {
  const { classList: n } = document.documentElement;
  e ? n.add(ft) : n.remove(ft);
}
function ll() {
  yt(gt.matches), gt.addEventListener(
    "change",
    (e) => yt(e.matches)
  );
}
export {
  Or as AlertProvider,
  Ka as ContinueCancelModal,
  rl as ErrorBoundaryFallback,
  nl as ErrorBoundaryProvider,
  Fr as ErrorPage,
  Bt as FormErrorText,
  jr as FormPanel,
  Ja as FormSubmitButton,
  Br as Help,
  Nt as HelpItem,
  za as IconMapper,
  Ia as KeycloakDataTable,
  tl as KeycloakMasthead,
  qa as KeycloakProvider,
  ia as KeycloakSelect,
  ya as KeycloakSpinner,
  Kr as KeycloakTextArea,
  va as ListEmptyState,
  Ua as NumberControl,
  al as OrganizationTable,
  Ca as PaginatingTableToolbar,
  Ha as PasswordControl,
  Vt as PasswordInput,
  Yr as ScrollForm,
  $r as SelectControl,
  re as SelectVariant,
  Wa as SwitchControl,
  ba as TableToolbar,
  ja as TextAreaControl,
  Ga as TextControl,
  el as UserProfileFields,
  Qa as beerify,
  ze as createNamedContext,
  Ya as debeerify,
  Sr as generateId,
  wr as getErrorDescription,
  Ir as getErrorMessage,
  $a as getInjectedEnvironment,
  xr as getNetworkErrorDescription,
  Tr as getNetworkErrorMessage,
  ll as initializeDarkMode,
  Ar as isDefined,
  Za as isUserProfileError,
  te as label,
  Qr as mainPageContentId,
  Xa as setUserProfileServerError,
  Va as useAlerts,
  _a as useEnvironment,
  Kt as useErrorBoundary,
  ga as useFetch,
  Lr as useHelp,
  Je as useRequiredContext,
  Er as useSetTimeout,
  Pt as useStoredState
};
